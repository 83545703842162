'use strict';

angular.module('saturdayBall')

.controller('GroupSettingsController', GroupSettingsController);

GroupSettingsController.$inject = ['$scope', '$routeParams', 'GroupService', 'Session', 'orderByFilter',
    'settingOptions', '$uibModal', '$document', 'inviteOptions', 'PlayerService', 'TeamService', '$timeout']

function GroupSettingsController($scope, $routeParams, GroupService, Session, orderBy,
    settingOptions, $uibModal, $document, inviteOptions, PlayerService, TeamService, $timeout) {

    $scope.applyDefaultTeamsBtnText = "Apply Team Names to all past games";
    $scope.applyDefaultTeamNames = applyDefaultTeamNames;
    $scope.changesDetected = false;
    $scope.close = close;
    $scope.defaultSeasonsButton = "Save";
    $scope.editingMembers = {};
    $scope.group = null;
    $scope.group_seasons = {};
    $scope.groupNameButtonText = "Save";
    $scope.groupNameSave = groupNameSave;
    $scope.invite = {};
    $scope.inviteOptions = inviteOptions;
    $scope.message = "";
    $scope.modify = modify;
    $scope.open = open;
    $scope.playerDisplay = playerDisplay;
    $scope.players = {};
    $scope.remove = remove;
    $scope.saveGameDefaults = saveGameDefaults;
    $scope.send = send;
    $scope.settings = undefined;
    $scope.start_date = null;
    $scope.end_date = null;
    $scope.season = {}
    $scope.createSeason = createSeason;
    $scope.updateSeason = updateSeason;
    $scope.deleteSeason = deleteSeason;
    $scope.fillSeasonForm = fillSeasonForm;
    $scope.settingOptions = settingOptions;
    $scope.update = update;
    $scope.updateDefaultSeasons = updateDefaultSeasons;
    $scope.updateChangesDetected = updateChangesDetected;
    $scope.updateActiveSeason = updateActiveSeason;
    $scope.user = Session.currentUser();
    $scope.delete_season_warning_message = "\nAre you sure you want to delete the season?\n\nThis will NOT delete games or statlines.\n\nThis only affects how stats are organized.";
    $scope.teamOptions = [];

    ///////////////////////

    init();

    function init() {
      GroupService.getGroup($routeParams.groupId).then(function(response) {
        $scope.settings = response;
        $scope.group = response;
        setMetaTags();
        if ($scope.group.is_league) {
          $scope.delete_season_warning_message = "\nAre you sure you want to delete the season?\n\nTHIS WILL DELETE ALL GAMES AND STATLINES IN THE SEASON.\n\nTeams and players will not be deleted";
        }
        getGroupSeasons(true);
      }, function(response) {
        console.log('Getting group failed: ', response);
      });
      getPlayers();
      getMembers();
    }

    function groupNameSave() {
      let data = {
        id: $scope.group.id,
        name: $scope.group.name,
        timezone: $scope.group.timezone
      }
      GroupService.patchGroup(data).then(function(response) {
        $scope.groupNameButtonText = "Saved";
        $timeout(function() {
          $scope.groupNameButtonText = "Save";
        }, 2000);
      }, function(response) {
        console.log("Updating group failed: ", response)
      });
    }

    function updateDefaultSeasons() {
      let data = {
        id: $scope.group.id,
        default_seasons: $scope.group.default_seasons
      }
      GroupService.patchGroup(data).then(function(response) {
        $scope.defaultSeasonsButton = "Saved";
        $timeout(function() {
          $scope.defaultSeasonsButton = "Save";
        }, 2000);
      }, function(response) {
        console.log("Updating group failed: ", response)
      });
    }

    function getPlayers() {
      PlayerService.getPlayers($routeParams.groupId).then(function(response) {
        $scope.players = _.filter(response, function(player){
          return (player.id !== 5 && player.id !== 6);
        });
      }, function(response) {
        console.log("Getting players failed: ", response)
      });
    }

    function getMembers() {
      GroupService.getMemberPermissions($routeParams.groupId).then(function(response) {
        $scope.members = response;
        for (var i = 0, length = $scope.members.length; i < length; i++) {
          $scope.editingMembers[$scope.members[i].id] = false;
        }
      }, function(response) {
        console.log("Getting members failed: ", response)
      });
    }

    function getGroupSeasons(only_custom) {
      let params = {
        only_custom: only_custom
      }
      GroupService.getGroupSeasons($routeParams.groupId, params).then(function(response) {
        $scope.group_seasons = response.seasons
        if ($scope.group.is_league) {
          // Loop over group seasons and sort teams by final_placement
          setSeasonTeamPlacements();
        }
        console.log("group seasons: ", $scope.group_seasons);
      }, function(response) {
        console.log("Getting group seasons failed: ", response)
      });
    }

    function setSeasonTeamPlacements() {
      for (var i = 0, length = $scope.group_seasons.length; i < length; i++) {
        $scope.group_seasons[i].teams = orderBy($scope.group_seasons[i].teams, "final_placement", false);
        // filter out teams that have not been placed
        let first_place_team = _.filter($scope.group_seasons[i].teams, function(team) {
          return team.final_placement === 1;
        });
        let second_place_team = _.filter($scope.group_seasons[i].teams, function(team) {
          return team.final_placement === 2;
        });
        let third_place_team = _.filter($scope.group_seasons[i].teams, function(team) {
          return team.final_placement === 3;
        });
        try {
          $scope.group_seasons[i].first_place_team = first_place_team.length ? first_place_team[0].team.id : null;
          $scope.group_seasons[i].second_place_team = second_place_team.length ? second_place_team[0].team.id : null;
          $scope.group_seasons[i].third_place_team = third_place_team.length ? third_place_team[0].team.id : null;
        } catch (e) {
          console.warn(e);
        }
      }
    }

    function modify(member){
        $scope.editingMembers[member.id] = true;
    };


    function update(member){
        $scope.editingMembers[member.id] = false;
        GroupService.updateMemberPermission($routeParams.groupId, member).then(function(response){
        }, function(response){
          console.log(response);
        })
    };

    function remove(member){
        GroupService.deleteMemberPermission($routeParams.groupId, member).then(function(response){
          getMembers();
        }, function(response){
          console.log(response);
        })
    };

    function saveGameDefaults() {
      let data = {
        id: $scope.group.id,
        game_type: $scope.settings.game_type,
        score_type: $scope.settings.score_type,
        ft_enabled: $scope.settings.ft_enabled,
        stats_method: $scope.settings.stats_method,
        team1_name: $scope.settings.team1_name,
        team2_name: $scope.settings.team2_name,
        possessions_min: $scope.settings.possessions_min,
        fga_min: $scope.settings.fga_min,
        fta_min: $scope.settings.fta_min,
        pga_min: $scope.settings.pga_min,
        track_team_stats: $scope.settings.track_team_stats,
      }
      $scope.message = "Saving..."
      GroupService.patchGroup(data).then(function(response) {
        $scope.message = "Saved Successfully";
        $scope.changesDetected = false;
      }, function(response){
        $scope.message = "Failed to save"
      });
    }

    function updateChangesDetected() {
      $scope.changesDetected = true
    }

    var modalInstance;
    function open(size, parentSelector) {
      var parentElem = parentSelector ?
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'static/partials/inviteModal.html',
        size: size,
        scope: $scope
      });

      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {
        console.log('Modal dismissed at: ' + new Date());
      });
    }

    function close() {
      modalInstance.close();
    }

    function send(form) {
      if (form.$valid) {
        $scope.inviteMessage = "Sending Invite...";
        $scope.invite.group = $routeParams.groupId;
        GroupService.createMemberInvite($routeParams.groupId, $scope.invite).then(function(response) {
          $scope.inviteMessage = "Invite Sent";
        }, function(response){
          $scope.inviteMessage = response.data.message;
        })
      }
    }

    function playerDisplay(id) {
      var filtered_player = _.filter($scope.players, function(player) {
        return player.id === id;
      })
      if (filtered_player.length) {
        return filtered_player[0].first_name + ' ' + filtered_player[0].last_name
      }
      else {
        return "No Player Selected"
      }
    }

    function applyDefaultTeamNames() {
      GroupService.applyDefaultTeamNames($routeParams.groupId).then(function(response) {
        $scope.applyDefaultTeamsBtnText = "Done";
        $timeout(function() {
          $scope.applyDefaultTeamsBtnText = "Apply Team Names to all past games";
        }, 2000);
      }, function(response){
        console.log("apply default names failed:", response);
        $scope.applyDefaultTeamsBtnText = "There was an error";
        $timeout(function() {
          $scope.applyDefaultTeamsBtnText = "Apply Team Names to all past games";
        }, 2000);
      })
    }

    function fillSeasonForm(season) {
      if (season) {
        $scope.season = {...season};
        if (season.start_date) {
          $scope.season.start_date = display_in_utc(season.start_date);
        }
        if (season.end_date) {
          $scope.season.end_date = display_in_utc(season.end_date);
        }
      }
      else {
        $scope.season = {};
      }
      $scope.seasonFormMessage = "";
    }

    function createSeason() {
      GroupService.createSeason($routeParams.groupId, $scope.season).then(function(response) {
        response['season']['start_date'] = new Date(response['season']['start_date']);
        response['season']['end_date'] = new Date(response['season']['end_date']);
        $scope.season = response['season'];
        $scope.seasonFormMessage = "Season created.  Stats have been calculated if applicable.";
        getGroupSeasons(true);
        $timeout(function() {
          $('#seasonFormModal').modal('hide');
        }, 1500);
      }, function(response){
        if (response.data.non_field_errors) {
          $scope.seasonFormMessage = response.data.non_field_errors[0];
        }
        else {
          $scope.seasonFormMessage = response.data;
        }
      })
    }

    function updateSeason() {
      GroupService.updateSeason($routeParams.groupId, $scope.season).then(function(response) {
        $scope.seasonFormMessage = "Season updated";
        getGroupSeasons(true);
        $timeout(function() {
          $('#seasonFormModal').modal('hide');
        }, 1500);
      }, function(response){
        if (response.data.non_field_errors) {
          $scope.seasonFormMessage = response.data.non_field_errors[0];
        }
        else {
          $scope.seasonFormMessage = response.data;
        }
      })
    }

    function updateActiveSeason(season) {
      season.is_active=true;
      console.log("season: ", season);
      GroupService.updateSeason($routeParams.groupId, season).then(function(response) {
      }, function(response){
        console.log(response);
      })
    }

    function deleteSeason(season_id) {
      GroupService.deleteSeason($routeParams.groupId, {"season_id": season_id}).then(function(response) {
        $scope.seasonFormMessage = "Season deleted";
        $scope.season = {};
        getGroupSeasons(true);
      }, function(response){
        $scope.seasonFormMessage = response.data;
      })
    }

    /* Date Picker logic */

    $scope.today = function() {
      $scope.dt = new Date();
    };
    $scope.today();
  
    $scope.clear = function() {
      $scope.dt = null;
    };
  
    $scope.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };
  
    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };
  
    $scope.toggleMin = function() {
      $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
      $scope.dateOptions.minDate = $scope.inlineOptions.minDate;
    };
  
    $scope.toggleMin();
  
    $scope.open1 = function() {
      $scope.popup1.opened = true;
    };
  
    $scope.open2 = function() {
      $scope.popup2.opened = true;
    };
  
    $scope.setDate = function(year, month, day) {
      $scope.dt = new Date(year, month, day);
    };
  
    $scope.formats = ['MM/dd/yyyy'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];
  
    $scope.popup1 = {
      opened: false
    };
  
    $scope.popup2 = {
      opened: false
    };
  
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    $scope.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];
  
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);
  
        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0,0,0,0);
  
          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
  
      return '';
    }

    /* end of date picker logic */

    function display_in_utc(date) {
      return new Date(new Date(date).toLocaleString('en', {timeZone: 'GMT'}))
    };

    function setMetaTags() {
      let title = 'Settings | ' + $scope.group.name;
      $scope.page_title = title;
      $scope.og_title = title;
    }
};
