'use strict';

angular.module('saturdayBall').factory('TeamService', TeamService);

function TeamService($q, $http) {

  var service = {
    getTeams: getTeams,
  };
  return service;

  /////////////////

  function getTeam(groupId, teamId) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/teams/'+teamId+'/').then(function(response){
      deferred.resolve(response.data);
    }, function(response) {
      deferred.reject(response);
    });
    return deferred.promise;
  };

  function getTeams(groupId, params) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/teams/', {"params": params}).then(function(response){
      deferred.resolve(response.data);
    }, function(response) {
      deferred.reject(response);
    });
    return deferred.promise;
  };

};
