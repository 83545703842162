'use strict';

angular.module('saturdayBall').factory('GameService', GameService);

GameService.$inject = ['$q', '$http', '$routeParams'];

function GameService($q, $http, $routeParams) {
  var apiurl;
  var myData;
  var service = {
    getGamePlays: getGamePlays,
    getGame: getGame,
    getGames: getGames,
    getGameBoxScore: getGameBoxScore,
    getGameAdvBoxScore: getGameAdvBoxScore,
    importBoxScoreCSV: importBoxScoreCSV,
    calculateStatlines: calculateStatlines,
    createPlay: createPlay,
    updatePlay: updatePlay,
    getPlay: getPlay,
    deletePlay: deletePlay
  };
  return service;

  /////////////////

  function getGamePlays(groupId, gameid) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/plays/?gameid=' + gameid).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function getGame(groupId, gameid) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/games/'+gameid+'/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function getGames(groupId, params) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/games/', {"params": params}).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function getGameBoxScore(groupId, gameId) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/games/'+gameId+'/box_score/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function getGameAdvBoxScore(groupId, gameId) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/games/'+gameId+'/advanced_box_score/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      console.error(response);
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function calculateStatlines(groupId, gameId) {
    var deferred = $q.defer();
    $http.post('/api/groups/'+groupId+'/games/'+gameId+'/calculate_statlines/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function importBoxScoreCSV(groupId, gameId, data) {
    var deferred = $q.defer();
    $http.post('/api/groups/'+groupId+'/games/'+gameId+'/import_box_score_csv/', data, {
        headers: {'Content-Type': undefined },
        transformRequest: angular.identity
    }).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      console.error(response);
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function createPlay(groupId, data){
    var deferred = $q.defer();
    $http.post('/api/groups/'+groupId+'/plays/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function updatePlay(groupId, play) {
    var deferred = $q.defer();
    $http.put('/api/groups/'+groupId+'/plays/'+play.id+'/', play).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function getPlay(groupId, playid) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/plays/'+playid+'/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function deletePlay(groupId, playid) {
    var deferred = $q.defer();
    $http.delete('/api/groups/'+groupId+'/plays/'+playid+'/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }
};
