angular.module('saturdayBall')

.controller('NavigationController', NavigationController);

NavigationController.$inject = ['$scope', '$rootScope', '$routeParams', '$route', 'Session', 'RoleHelper', 'GroupService']

function NavigationController($scope, $rootScope, $routeParams, $route, Session, RoleHelper, GroupService) {

    $scope.groupId = "";
    $scope.group = null;
    $scope.RoleHelper = RoleHelper;
    $scope.user = {};
    $scope.$route = $route;
    $rootScope.season_slug_param = $routeParams.season_slug

    ////////////////

    $scope.$watch('$route.current', function () {
        if ("current" in $route) {
            if ("groupId" in $route.current.pathParams && $route.current.pathParams.groupId != $scope.groupId){
                $scope.groupId = $route.current.pathParams.groupId
                GroupService.getGroup($scope.groupId).then(function(response) {
                    $scope.group = response;
                  }, function(response) {
                    console.log('Getting group failed: ', response);
                  });
            }
            else if ($route.current.pathParams.groupId === undefined) {
                $scope.groupId = "";
                $scope.group = null;
            }
        }
    });


    $scope.$watch('session.currentUser().username', function () {
        $scope.user = Session.currentUser();
    });

};
