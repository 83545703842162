'use strict';

angular.module('saturdayBall').factory('GroupService', GroupService);

GroupService.$inject = ['$q', '$http'];

function GroupService($q, $http){
  var service = {
      getGroup: getGroup,
      getGroupSeasons: getGroupSeasons,
      createSeason: createSeason,
      updateSeason: updateSeason,
      deleteSeason: deleteSeason,
      updateGroup: updateGroup,
      patchGroup: patchGroup,
      createGroup: createGroup,
      isGroupAdmin: isGroupAdmin,
      getMemberPermissions: getMemberPermissions,
      updateMemberPermission: updateMemberPermission,
      deleteMemberPermission: deleteMemberPermission,
      createMemberInvite: createMemberInvite,
      updateMemberInvite: updateMemberInvite,
      applyDefaultTeamNames: applyDefaultTeamNames
  };
  return service;

  ////////////////////

  function getGroup(groupid) {
    var deferred = $q.defer();
    $http.get('/api/groups/' + groupid + '/').then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function updateGroup(data) {
    var deferred = $q.defer();
    $http.put('/api/groups/' + data.id + '/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function patchGroup(data) {
    var deferred = $q.defer();
    $http.patch('/api/groups/' + data.id + '/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function createGroup(data) {
    var deferred = $q.defer();
    $http.post('/api/groups/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function isGroupAdmin(groupid) {
    var deferred = $q.defer();
    $http.get('/api/groups/' + groupid + '/verify-group-admin/').then(function(response, status, config, headers){
      deferred.resolve(response.data.message);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function getGroupSeasons(groupId, params) {
    var deferred = $q.defer();
    $http.get('/api/groups/' + groupId + '/seasons/', {params: params}).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function createSeason(groupId, data) {
    var deferred = $q.defer();
    $http.post('/api/groups/' + groupId + '/create_season/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function updateSeason(groupId, data) {
    var deferred = $q.defer();
    $http.put('/api/groups/' + groupId + '/update_season/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function deleteSeason(groupId, data) {
    var deferred = $q.defer();
    $http.delete('/api/groups/' + groupId + '/delete_season/', {data: data, headers: {"Content-Type": "application/json"}}).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function getMemberPermissions(groupId, params) {
    var deferred = $q.defer();
    $http.get('/api/groups/'+groupId+'/member-permissions/', {params: params}).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

  function updateMemberPermission(groupId, data) {
    var deferred = $q.defer();
    $http.put('/api/groups/'+groupId+'/member-permissions/' + data.id + '/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function deleteMemberPermission(groupId, data) {
    var deferred = $q.defer();
    $http.delete('/api/groups/'+groupId+'/member-permissions/' + data.id + '/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function createMemberInvite(groupId, data) {
    var deferred = $q.defer();
    $http.post('/api/groups/'+groupId+'/member-invite/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function updateMemberInvite(groupId, data) {
    var deferred = $q.defer();
    $http.put('/api/groups/'+groupId+'/member-invite/'+data.code+'/', data).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }

  function applyDefaultTeamNames(groupId) {
    var deferred = $q.defer();
    $http.post('/api/group/' + groupId + '/apply_default_team_names/', {}).then(function(response, status, config, headers){
      deferred.resolve(response.data);
    }, function(response){
      deferred.reject(response);
    });
    return deferred.promise;
  }
};
