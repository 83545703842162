angular.module('saturdayBall')

.controller('CreateGroupController', CreateGroupController);

CreateGroupController.$inject = ['$scope', '$location', 'GroupService', 'Session', 'settingOptions']

function CreateGroupController($scope, $location, GroupService, Session, settingOptions) {

    $scope.message = "";
    $scope.save = save;
    $scope.season = {}
    $scope.settingOptions = settingOptions;
    $scope.settingOptions.FORMAT_OPTIONS = [
      {'code': false, 'name': 'Pickup'},
      {'code': true, 'name': 'League (BETA)'}
    ]
    $scope.settings = {
      score_type: $scope.settingOptions.SCORE_TYPES[0].code,
      game_type: $scope.settingOptions.GAME_TYPES[0].code,
      timezone: 'US/Eastern',
      ft_enabled: false,
      tf_enabled: false,
      stats_method: 'plays',
      possessions_min: 100,
      is_league: false,
      fga_min: 15,
      fta_min: 5
    };
    $scope.user = {};

    ////////////////////

    init();

    function init() {
      $scope.user = Session.currentUser();
    }

    function save() {
      $scope.message = "Saving..."
      if ($scope.settings.is_league == false) {
        $scope.settings.timezone = 'UTC';
      }
      else {
        $scope.settings.default_seasons = false;
      }
      GroupService.createGroup($scope.settings).then(function(response) {
        let group_id = response.id
        if ($scope.settings.is_league == true){
          GroupService.createSeason(group_id, $scope.season).then(function(response) {
            $scope.message = "Saved Successfully";
            window.location.replace('/group/' + group_id);
          });
        } else {
          $scope.message = "Saved Successfully";
          window.location.replace('/group/' + group_id);
        }
      }, function(response) { 
        if (response.status == 406) {
          $scope.message = "Invalid Beta Code";
        }
        else {
          $scope.message = "Failed"
        }
      });
    }

    /* Date Picker logic */

    $scope.today = function() {
      $scope.dt = new Date();
    };
    $scope.today();
  
    $scope.clear = function() {
      $scope.dt = null;
    };
  
    $scope.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };
  
    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };
  
    $scope.toggleMin = function() {
      $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
      $scope.dateOptions.minDate = $scope.inlineOptions.minDate;
    };
  
    $scope.toggleMin();
  
    $scope.open1 = function() {
      $scope.popup1.opened = true;
    };
  
    $scope.open2 = function() {
      $scope.popup2.opened = true;
    };
  
    $scope.setDate = function(year, month, day) {
      $scope.dt = new Date(year, month, day);
    };
  
    $scope.formats = ['MM/dd/yyyy'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];
  
    $scope.popup1 = {
      opened: false
    };
  
    $scope.popup2 = {
      opened: false
    };
  
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    $scope.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];
  
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);
  
        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0,0,0,0);
  
          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
  
      return '';
    }

    /* end of date picker logic */
};
