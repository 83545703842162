'use strict';

angular
    .module('saturdayBall')
    .directive('smallLeaderboard', smallLeaderboard);

smallLeaderboard.$inject = ['PlayerService', '$q', 'Per100Service']

function smallLeaderboard(PlayerService, $q, Per100Service) {
    var directive = {
        link: link,
        scope: {
          title: '@',
          stat: '@',
          color: '@',
          per100Statlines: '=',
          filterForm: '=',
          tooltipDesc: '@'
        },
        templateUrl: 'static/partials/directives/smallLeaderboard.html',
        restrict: 'EA'
    };
    return directive;

    function link(scope, element, attrs) {
      scope.leaderboard = [];
      
      function updateSmallLeaderboard() {
        scope.calculating = true;
        if (['fgm_percent', 'ts_percent', 'twopm_percent'].indexOf(scope.stat) > -1) {
          scope.leaderboard = _.chain(scope.per100Statlines)
                              .filter(function(statline) {
                                return (statline.off_pos >= scope.filterForm.possessions_min &&
                                        statline.fga >= scope.filterForm.fga_min)
                              })
                              .orderBy([scope.stat], ['desc'])
                              .slice(0,10)
                              .value();
        } else if (['threepm_percent'].indexOf(scope.stat) > -1) {
          scope.leaderboard = _.chain(scope.per100Statlines)
                              .filter(function(statline) {
                                return (statline.off_pos >= scope.filterForm.possessions_min &&
                                        statline.threepa >= scope.filterForm.fga_min)
                              })
                              .orderBy([scope.stat], ['desc'])
                              .slice(0,10)
                              .value();
        } else if (['ftm_percent'].indexOf(scope.stat) > -1) {
          scope.leaderboard = _.chain(scope.per100Statlines)
                              .filter(function(statline) {
                                return (statline.off_pos >= scope.filterForm.possessions_min &&
                                        statline.fta >= scope.filterForm.fta_min)
                              })
                              .orderBy([scope.stat], ['desc'])
                              .slice(0,5)
                              .value();
          } else if (['pgm_percent'].indexOf(scope.stat) > -1) {
            scope.leaderboard = _.chain(scope.per100Statlines)
                                .filter(function(statline) {
                                  return (statline.off_pos >= scope.filterForm.possessions_min &&
                                          statline.pga >= scope.filterForm.pga_min)
                                })
                                .orderBy([scope.stat], ['desc'])
                                .slice(0,10)
                                .value();
          } else {
            var filtered_leaderboard = _.filter(scope.per100Statlines, function(statline) {
                                        return (statline.off_pos > scope.filterForm.possessions_min)
                                      });
            if(scope.stat === 'def_rating') {
              scope.leaderboard = _.chain(filtered_leaderboard)
                                  .orderBy(['def_rating'], ['asc'])
                                  .slice(0,10)
                                  .value();

            } else {
              // if(scope.stat === 'ast_to_ratio') {
              //   console.log('ast_to_ratio', _.chain(filtered_leaderboard).orderBy([scope.stat], ['desc']).value())
              // }
              scope.leaderboard = _.chain(filtered_leaderboard)
                                  .orderBy([scope.stat], ['desc'])
                                  .slice(0,10)
                                  .value();
            }
        }
        scope.calculating = false;
      };

      scope.$watch('per100Statlines', function() {
        if (scope.per100Statlines) {
          updateSmallLeaderboard();
        }
      });

      scope.$watch('filterForm', function() {
        scope.leaderboard = [];
        scope.calculating = true;
      }, true);

    }
}
