'use strict';

angular.module('saturdayBall').controller('PlayWizardController', PlayWizardController);

PlayWizardController.$inject = ['$scope', '$routeParams', 'GameService', 'Session', 'playOptions',
  '$anchorScroll'];

function PlayWizardController($scope, $routeParams, GameService, Session, playOptions,
  $anchorScroll) {
    var vm = this;
    vm.checkTeam = checkTeam;
    vm.currentStep = 0;
    vm.currentTemplate = "static/partials/playWizard/step0.html";
    vm.eligiblePlayerFilter = eligiblePlayerFilter;
    vm.initPlay = {
      "potential_fastbreak": false,
      "potential_putback": false,
      "is_fastbreak": false,
      "is_putback": false
    };
    vm.nonPlayerFilter = nonPlayerFilter;
    vm.prevPlay = null;
    vm.play = {...vm.initPlay};
    vm.startOver = startOver;
    vm.stepTemplates = [
      "static/partials/playWizard/step0.html",
      "static/partials/playWizard/step1.html",
      "static/partials/playWizard/step2.html",
      "static/partials/playWizard/step3.html",
      "static/partials/playWizard/step4.html",
      "static/partials/playWizard/step5.html",
    ];
    vm.step0 = step0;
    vm.step1 = step1;
    vm.step2 = step2;
    vm.step3 = step3;
    vm.step4 = step4;
    vm.step5 = step5;
    vm.toggleFastbreak = toggleFastbreak;
    vm.togglePutback = togglePutback;

    ////////////////////

    function nonPlayerFilter(player) {
      return player.id != 5 && player.id != 6;
    }

    function eligiblePlayerFilter(player) {
      return (player.id != vm.play.primary_player) && player.id != 5 && player.id != 6;
    }

    function checkTeam(team, player_id) {
      return team.some(function(player) {
        return player.id == player_id
      })
    }

    function toggleFastbreak() {
      vm.play['is_fastbreak'] = !vm.play['is_fastbreak'];
    }

    function togglePutback() {
      vm.play['is_putback'] = !vm.play['is_putback'];
    }

    function goToStep(step) {
      vm.currentStep = step;
      vm.currentTemplate = vm.stepTemplates[vm.currentStep];
    }

    function startOver() {
      vm.play = {...vm.initPlay};
      goToStep(0);
    }

    function step0(primary_play) {
      // What type of play occurred
      vm.play['primary_play'] = primary_play;
      goToStep(1);
      $anchorScroll("step-header-anchor");
    }

    function step1(primary_player_id, first_name, last_name) {
      // Lets make sure we have a prev play at this point, if applicable
      if (!vm.prevPlay && $scope.$parent.plays.length > 0) {
        vm.prevPlay = {...$scope.$parent.plays[0]};
      };

      // Who was the primary player
      vm.play.primary_player = primary_player_id;
      vm.play.primary_player_display = first_name + ' ' + last_name

      // check if potential putback
      if ((['fga', 'fgm', 'fta'].indexOf(vm.play.primary_play) !== -1) && vm.prevPlay && vm.prevPlay.secondary_play === 'oreb' && vm.play.primary_player === vm.prevPlay.secondary_player.id) {
        vm.play.potential_putback = true
      };

      // check if potential fastbreak
      if (['fgm', 'threepm'].indexOf(vm.play.primary_play) !== -1) {
        // If prevPlay was a dreb or stl and our next play was a basket, ask user in step 4 if it was a fastbreak
        if (vm.prevPlay && (['dreb', 'stls'].indexOf(vm.prevPlay.secondary_play) > -1) && (
                checkTeam($scope.$parent.game.team1_members, vm.prevPlay.primary_player.id) && checkTeam($scope.$parent.game.team2_members, vm.play.primary_player) ||
                checkTeam($scope.$parent.game.team2_members, vm.prevPlay.primary_player.id) && checkTeam($scope.$parent.game.team1_members, vm.play.primary_player)
              )
            ) {
          vm.play.potential_fastbreak = true;
        };
      };

      if (['fga', 'threepa', 'blk', 'fta', 'pf', 'to', 'sub_out'].indexOf(vm.play.primary_play) !== -1) {
        goToStep(2);
      } else if (['fgm', 'threepm'].indexOf(vm.play.primary_play) !== -1) {
        goToStep(3);
      } else {
        goToStep(5);
      };

      $anchorScroll("step-header-anchor");
    }

    function step2(secondary_player_id, first_name, last_name) {
      // What was the secondary play and player
      if ((['fga', 'threepa', 'fta'].indexOf(vm.play.primary_play) > -1) && secondary_player_id) {
        if ((checkTeam($scope.$parent.game.team1_members, secondary_player_id) && checkTeam($scope.$parent.game.team1_members, vm.play.primary_player)) ||
            (checkTeam($scope.$parent.game.team2_members, secondary_player_id) && checkTeam($scope.$parent.game.team2_members, vm.play.primary_player))) {
          vm.play['secondary_play'] = 'oreb';
        }
        else {
          vm.play['secondary_play'] = 'dreb';
        }
      } else if (vm.play.primary_play === 'blk') {
        vm.play['secondary_play'] = 'ba';
      } else if (vm.play.primary_play === 'to' && secondary_player_id) {
        vm.play['secondary_play'] = 'stls';
      } else if (vm.play.primary_play === 'pf') {
        vm.play['secondary_play'] = 'fd';
      } else if (vm.play.primary_play === 'sub_out') {
        vm.play['secondary_play'] = 'sub_in';
      }

      if (secondary_player_id) {
        vm.play['secondary_player'] = secondary_player_id;
        vm.play['secondary_player_display'] = first_name + ' ' + last_name
      }

      if (['fga', 'threepa'].indexOf(vm.play.primary_play) > -1) {
        goToStep(3);
      } else {
        goToStep(5);
      }
      $anchorScroll("step-header-anchor");
    }

    function step3(assist_player, first_name, last_name) {
      // Was there an assist and who was the player
      if (assist_player) {
        if (['fgm', 'threepm'].indexOf(vm.play['primary_play']) > -1) {
          vm.play.assist = "asts";
        }
        else {
          vm.play.assist = "pot_ast";
        }
        vm.play.assist_player = assist_player
        vm.play.assist_player_display = first_name + ' ' + last_name
      }
      if (vm.play.potential_putback || vm.play.potential_fastbreak) {
        goToStep(4);
      }
      else{
        goToStep(5);
      }
      $anchorScroll("step-header-anchor");
    }

    function step4() {
      // if we are calling this method then the potential event was true
      // right now we prioritize fastbreaks over putbacks.  Need to fix that some day.
      if (vm.play.potential_fastbreak) {
        vm.play.is_fastbreak = true
      }
      else if (vm.play.potential_putback) {
        vm.play.is_putback = true;
      }
      goToStep(5);
      $anchorScroll("step-header-anchor");
    }

    function step5() {
      // Submit the play
      vm.play['time'] = $scope.$parent.calculateTime(vm.play, $scope.$parent.plays);
      vm.play['game'] = $scope.$parent.game.id;
      GameService.createPlay($routeParams.groupId, vm.play).then(function(response){
        // hacky way to make sure we still account for fastbreaks after rebounds
        if (response.primary_play !== 'blk') {
          vm.prevPlay = {...response};
        }
        $scope.$parent.plays.unshift(response);
        $scope.$parent.calculateScore();
        GameService.calculateStatlines($routeParams.groupId, $scope.$parent.game.id).then(function(response){});
      }, function(response) {
        alert("Something went wrong.  Last play not added.");
        console.log("Create Play failed: ", response);
      });
      vm.play = {...vm.initPlay};
      goToStep(0);
      $anchorScroll("step-header-anchor");
    }

}
