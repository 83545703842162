'use strict';

angular.module('saturdayBall').controller('LeaderboardController', LeaderboardController);

LeaderboardController.$inject = ['$scope', '$rootScope', '$routeParams', '$location', 'GroupService', 'PlayerService',
    'StatlineService', 'Session', 'Per100Service', 'statDescriptions', 'settingOptions'];

function LeaderboardController($scope, $rootScope, $routeParams, $location, GroupService, PlayerService,
  StatlineService, Session, Per100Service, statDescriptions, settingOptions) {

  $scope.groupId = $routeParams.groupId;
  $scope.filterForm = {};
  $scope.filterOptions = {'seasons': [], 'season_types': []};
  $scope.header = '';
  $scope.isFormVisible = false;
  $scope.ShowHideForm = ShowHideForm;
  $scope.statlines = [];
  $scope.statDescriptions = statDescriptions;
  $scope.per100Statlines = [];
  $scope.playerType = $routeParams.playerType === 'teams' ? 'team' : 'player';
  $scope.season_slug = $routeParams.season_slug;

  init();

  ///////////////////////

  function init() {
    set_header_title();
    GroupService.getGroup($routeParams.groupId).then(function(response) {
      $scope.group = response;
      setMetaTags();
      getSeasons($scope.group);
      $scope.filterForm.possessions_min = $scope.group.possessions_min;
      $scope.filterForm.fga_min = $scope.group.fga_min;
      $scope.filterForm.fta_min = $scope.group.fta_min;
      $scope.filterForm.pga_min = $scope.group.pga_min;
      $scope.filterForm.game_type = $scope.group.game_type;

      if ($scope.group.is_league) {
        $scope.filterForm.season_type = 'regular';
        $scope.filterOptions.season_types = settingOptions.SEASON_TYPES;
      }

      if ($scope.group.ft_enabled) {
        $scope.statDescriptions['ts_percent'] = `True Shooting Percentage. Measures a ${$scope.playerType}\'s efficiency at shooting the ball.  Formula is Points / 2(FGA + (.44*FTA))`;
      }
    }, function(response) {
      console.log('Failed: ', response);
    });
    
    $scope.filterOptions.game_types = settingOptions.GAME_TYPES;
  }

  function set_header_title() {
    if ($scope.playerType === 'player') {
      $scope.header = 'Player Stats';
    }
    else if ($scope.playerType === 'team') {
      $scope.header = 'Team Stats';
    }
    else {
      $scope.header = 'Stats';
    }
  }

  function getSeasons(group) {
    // Get all the seasons and choose our default season for the leaderboard
    let seasons_params = {}
    if (group.is_league) {
      seasons_params = {
        'only_custom': false,
        'all': true
      }
    }
    GroupService.getGroupSeasons(group.id, seasons_params).then(function(response) {
      response.seasons.push({id:0, title: 'All Time'});
      $scope.filterOptions.seasons = response.seasons;
      if (group.is_league) {
        let active_season = response.seasons.find((season) => {
          if ($scope.season_slug && season.slug == $scope.season_slug) {
            return true
          } else if (!$scope.season_slug && season.is_active){
            return true
          }
          else {
            return false
          }
        })
        if (!active_season) {
          $scope.filterForm.season = response.seasons[0].id;
        }
        else {
          $scope.filterForm.season = active_season.id;
        }
      }
      else {
        $scope.filterForm.season = response.seasons[0].id;
      }
    }, function(response) {
      console.log("Failed: ", response);
    })
  }

  function generateTotalStatlines() {

    var query = "?game_type="+$scope.filterForm.game_type+"&group_id="+$routeParams.groupId;
    if ($routeParams.playerType) {
      query += "&player_type="+$scope.playerType;
    }
    // if season is not AllTime (aka 0), grab season specific statlines
    if ($scope.filterForm.season != 0) {
      query += $scope.filterForm.season === 0 ? "" : '&season=' + $scope.filterForm.season;
      if ($scope.filterForm.season_type) {
        query += "&season_type="+$scope.filterForm.season_type;
      }
      StatlineService.getSeasonStatlines($routeParams.groupId, query).then(function(response) {
        if ($scope.playerType === 'player') {
          var grouped_lines = _.chain(response)
                            .groupBy(function(s) {
                              return s.player.id
                            })
                            .map(function(statlines, key) {
                              var player = {
                                id: key,
                                player: statlines[0].player
                              };
                              _.assign(player, StatlineService.sumStatlines(statlines, key));
                              return player;
                            })
                            .filter(function(statline) {
                              return ([5, 6].indexOf(statline.player.id) <= -1)
                            })
                            .orderBy(['player.first_name'], ['asc'])
                            .value()
        }
        else if ($scope.playerType === 'team') {
          var grouped_lines = _.chain(response)
                              .groupBy(function(s) {
                                return s.team.id
                              })
                              .map(function(statlines, key) {
                                var team = {
                                  id: key,
                                  team: statlines[0].team
                                };
                                _.assign(team, StatlineService.sumStatlines(statlines, key));
                                return team;
                              })
                              .orderBy(['team.name'], ['asc'])
                              .value()
        }
        $scope.statlines = grouped_lines;
        $scope.per100Statlines = createPer100Statlines();
      });
    }
    else {
      StatlineService.getAllTimeTotalsStatlines($routeParams.groupId, query).then(function(response) {
        $scope.statlines = response;
        $scope.per100Statlines = createPer100Statlines();
      });
    }
  }

  function createPer100Statlines() {
    return Per100Service.calculatePer100Statlines($scope.statlines, $scope.group);
  }

  function ShowHideForm() {
      $scope.isFormVisible = $scope.isFormVisible ? false : true;
  }

  function sortTotalsBoard(stat) {
    _.orderBy($scope.totalsBoardStatlines, [stat], ['asc'])
  }

  function capitalize(s)
  {
      return s[0].toUpperCase() + s.slice(1);
  }

  function setMetaTags() {
    let title = capitalize($scope.playerType) + ' Stats | ' + $scope.group.name;
    $scope.page_title = title;
    $scope.og_title = title;
    $scope.og_description = `Leaderboard for ${$scope.group.name}`;
  }


  $scope.$watch('filterForm', function(newVal, oldVal) {
    if(newVal!=oldVal) {
      $scope.season = _.find($scope.filterOptions.seasons, {'id': $scope.filterForm.season});
      if ($scope.season) {
        if ($scope.season.is_active == false) {
          $rootScope.season_slug_param = $scope.season.slug;
        }
        else {
          $rootScope.season_slug_param = null;
        }
        generateTotalStatlines();
      }
    }
  }, true);


};
