'use strict';

angular.module('saturdayBall').controller('NoVideoAddPlaysController', NoVideoAddPlaysController);

NoVideoAddPlaysController.$inject = ['$scope', '$routeParams', 'GroupService', 'GameService', 'Session', 'playOptions',
  '$anchorScroll', '$timeout'];

function NoVideoAddPlaysController($scope, $routeParams, GroupService, GameService, Session, playOptions,
  $anchorScroll, $timeout) {

    $scope.video_url = "VS4Vk_cZJsg"
    $scope.createNoVideoPlay = createNoVideoPlay;

    ///////////////////////

    function createNoVideoPlay(play) {

        play.time = $scope.$parent.calculateTime(play, $scope.$parent.plays);

        $scope.$parent.createPlay(play);

      };

};
