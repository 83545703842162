'use strict';

angular.module('saturdayBall').controller('AddPlaysController', AddPlaysController);

AddPlaysController.$inject = ['$scope', '$routeParams', 'GroupService', 'GameService', 'Session', 'playOptions',
  '$anchorScroll', '$timeout', 'focus', '$uibModal'];

function AddPlaysController($scope, $routeParams, GroupService, GameService, Session, playOptions,
  $anchorScroll, $timeout, focus, $uibModal) {

    $scope.calculateScore = calculateScore;
    $scope.calculateTime = calculateTime;
    $scope.changeState = changeState;
    $scope.closeEditForm = closeEditForm;
    $scope.createPlay = createPlay;
    $scope.deletePlay = deletePlay;
    $scope.editplay = {};
    $scope.fillEditForm = fillEditForm;
    $scope.game = {};
    $scope.groupId = $routeParams['groupId'];
    $scope.pressedKeysMap = {
      "Shift": false,
      "Control": false,
      "ArrowDown": false,
      "' '": false, // spacebar
      "ArrowLeft": false,
      "ArrowRight": false,
      "Enter": false,
      'Meta': false
    };
    $scope.loading = true;
    $scope.play = {};
    $scope.playform = {};
    $scope.playOptions = playOptions;
    $scope.playsErrorMessage = "";
    $scope.seekToTime = seekToTime;
    $scope.ctrlrState = "with_video";
    $scope.triggerBackwardSeekMessage = triggerBackwardSeekMessage;
    $scope.triggerForwardSeekMessage = triggerForwardSeekMessage;
    $scope.showBackwardSeekMessage = false;
    $scope.showForwardSeekMessage = false;
    $scope.team1_score = "-";
    $scope.team2_score = "-";
    $scope.team1_player_ids = [];
    $scope.team2_player_ids = [];
    $scope.updatePlay = updatePlay;
    $scope.user = Session.currentUser();
    $scope.video_url = "VS4Vk_cZJsg"

    ///////////////////////

    init();

    function init() {
      $scope.playOptions.PLAYERS = [];
      GroupService.getGroup($routeParams['groupId']).then(function (response){
        var group = response;
        if (!group.ft_enabled) {
          $scope.playOptions.PRIMARY_PLAY = playOptions.PRIMARY_PLAY.filter((el) => el.code != "ftm" && el.code != "fta");
        }
        if (!group.tf_enabled) {
          $scope.playOptions.PRIMARY_PLAY = playOptions.PRIMARY_PLAY.filter((el) => el.code != "tf");
        }
      });
      
      GameService.getGame($routeParams['groupId'], $routeParams['gameid']).then(function (response){
        $scope.game = response;
        setMetaTags();
        // Grab the ids of the players on each team
        $scope.team1_player_ids = $scope.game.team1_members.map((obj) => obj.id);
        $scope.team2_player_ids = $scope.game.team2_members.map((obj) => obj.id);

        // Initialize the player options for each team with the 'team' player first
        set_team_player_options();

        // Loop through all the players and add them as options for the team they play for.
        angular.forEach($scope.game.team1_members, function(value, key) {
          if (value.id !== 5 && value.id !== 6 && value.is_real_player !== false){
              $scope.playOptions.TEAM1_PLAYERS.push({'code':value.id, 'name': value.first_name + ' ' + value.last_name, 'isRealPlayer': value.is_real_player});
          }
        });
        angular.forEach($scope.game.team2_members, function(value, key) {
          if (value.id !== 5 && value.id !== 6 && value.is_real_player !== false){
              $scope.playOptions.TEAM2_PLAYERS.push({'code':value.id, 'name': value.first_name + ' ' + value.last_name, 'isRealPlayer': value.is_real_player});
          }
        });

        // Set the Primary Player option by combining the two teams
        $scope.playOptions.PLAYERS = $scope.playOptions.TEAM1_PLAYERS.concat($scope.playOptions.TEAM2_PLAYERS);
        
        $scope.playOptions.SECONDARY_PLAY = $scope.playOptions.ALL_SECONDARY_PLAYS;
        $scope.playOptions.SECONDARY_PLAYERS = $scope.playOptions.PLAYERS;
        $scope.playOptions.ASSIST_PLAY = $scope.playOptions.ALL_ASSIST_PLAYS;
        $scope.playOptions.ASSIST_PLAYERS = $scope.playOptions.PLAYERS;
        $scope.playOptions.HIGHLIGHT_PLAYERS = remove_team_player_options($scope.playOptions.PLAYERS);

        $scope.playOptions.EDIT_SECONDARY_PLAY = $scope.playOptions.ALL_SECONDARY_PLAYS;
        $scope.playOptions.EDIT_SECONDARY_PLAYERS = $scope.playOptions.PLAYERS;
        $scope.playOptions.EDIT_ASSIST_PLAY = $scope.playOptions.ALL_ASSIST_PLAYS;
        $scope.playOptions.EDIT_ASSIST_PLAYERS = remove_team_player_options($scope.playOptions.PLAYERS);
        $scope.playOptions.EDIT_HIGHLIGHT_PLAYERS = remove_team_player_options($scope.playOptions.PLAYERS);

        $scope.loading = false;
        getPlays();
      });

    }

    function set_team_player_options() {
      let team1_option, team2_option;
      if ($scope.game.group.is_league) {
        if ($scope.game.team1) {
          angular.forEach($scope.game.team1_members, function(value, key) {
            if (value.is_real_player === false){
              team1_option = {'code': value.id, 'name': $scope.game.team1_name + " (Team)", 'isRealPlayer': false};
              return;
            }
          });
        } else {
          team1_option = {'code': 0, 'name': $scope.game.team1_name + " (Team)", 'isRealPlayer': false};
        }
        $scope.playOptions.TEAM1_PLAYERS = [team1_option];
  
        if ($scope.game.team2) {
          angular.forEach($scope.game.team2_members, function(value, key) {
            if (value.is_real_player === false){
              team2_option = {'code': value.id, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
              return;
            }
          });
        } else {
          team2_option = {'code': 0, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
        }
        $scope.playOptions.TEAM2_PLAYERS = [team2_option];
      } else {
        team1_option = {'code': 5, 'name': $scope.game.team1_name + " (Team)", 'isRealPlayer': false};
        team2_option = {'code': 6, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
        $scope.playOptions.TEAM1_PLAYERS = [team1_option];
        $scope.playOptions.TEAM2_PLAYERS = [team2_option];
      }
    }

    function remove_team_player_options(players) {
      let newPlayersList = [];
      angular.forEach(players, function(value, key){
        if (value.isRealPlayer === true) {
          newPlayersList.push(value);
        }
      })
      return newPlayersList
    }

    function restrictSecondaryPlay(form_type, newValue, oldValue) {
      var secondary_play_options, assist_play_options;

      if (['fga', 'threepa', 'fta'].includes(newValue)) {
        secondary_play_options = [
          {'code': 'dreb', 'name': 'DREB'},
          {'code': 'oreb', 'name': 'OREB'},
        ];
      }
      else if (['fgm', 'threepm', 'ftm', 'tf', 'misc'].includes(newValue)) {
        secondary_play_options = [];
      }
      else if (['blk'].includes(newValue)) {
        secondary_play_options = [
          {'code': 'ba', 'name': 'Block Against'}
        ];
        
        if (form_type == 'main_form') {
          $scope.play.secondary_play = 'ba';
        }
        else {
          $scope.editplay.secondary_play = 'ba';
        }
      }
      else if (['to'].includes(newValue)) {
        secondary_play_options = [
          {'code': 'stls', 'name': 'STL'}
        ];
      }
      else if (['pf'].includes(newValue)) {
        secondary_play_options = [
          {'code': 'fd', 'name': 'Foul Drawn'}
        ];
        if (form_type == 'main_form') {
          $scope.play.secondary_play = 'fd';
        }
        else {
          $scope.editplay.secondary_play = 'fd';
        }
      }
      else if (['sub_out'].includes(newValue)) {
        secondary_play_options = [
          {'code': 'sub_in', 'name': 'SUB IN'}
        ];
        if (form_type == 'main_form') {
          $scope.play.secondary_play = 'sub_in';
        }
        else {
          $scope.editplay.secondary_play = 'sub_in';
        }
      }
      else {
        secondary_play_options = $scope.playOptions.ALL_SECONDARY_PLAYS;
      }

      // Handle assist options
      if (['fgm', 'threepm'].includes(newValue)) {
        assist_play_options = [{'code': 'asts', 'name': 'Assist'}]
      }
      else if (['fga', 'threepa'].includes(newValue)) {
        assist_play_options = [{'code': 'pot_ast', 'name': 'Potential Assist'}]
      }
      else if (['ftm', 'fta', 'blk', 'to', 'tf', 'pf', 'sub_out', 'misc'].includes(newValue)){
        assist_play_options = []
      }
      else {
        assist_play_options = $scope.playOptions.ALL_ASSIST_PLAYS;
      }

      if (form_type == "main_form") {
        $scope.playOptions.SECONDARY_PLAY = secondary_play_options;
        $scope.playOptions.ASSIST_PLAY = assist_play_options;
      }
      else {
        $scope.playOptions.EDIT_SECONDARY_PLAY = secondary_play_options;
        $scope.playOptions.EDIT_ASSIST_PLAY = assist_play_options;
      }
    };

    // Restrict secondary and assist play options in a play form based on the chosen primary play 
    $scope.$watch('play.primary_play', function(newValue, oldValue) {
      restrictSecondaryPlay('main_form', newValue, oldValue)
    });

    $scope.$watch('editplay.primary_play', function(newValue, oldValue) {
      restrictSecondaryPlay('edit_form', newValue, oldValue)
    });

    function restrictSecondaryPlayer(form_type, primary_player, newValues, oldValues) {
      var secondary_player_options;
      
      if (!primary_player) {
        secondary_player_options = $scope.playOptions.PLAYERS;
      }
      else if (['dreb', 'stls', 'ba', 'fd'].includes(newValues[1])) {
        if ($scope.team1_player_ids.includes(primary_player)) {
          secondary_player_options = $scope.playOptions.TEAM2_PLAYERS;
        }
        else if ($scope.team2_player_ids.includes(primary_player)) {
          secondary_player_options = $scope.playOptions.TEAM1_PLAYERS;
        }
      }
      else if (['oreb', 'sub_in'].includes(newValues[1])) {
        if ($scope.team1_player_ids.includes(primary_player)) {
          secondary_player_options = Array.from($scope.playOptions.TEAM1_PLAYERS);
        }
        else if ($scope.team2_player_ids.includes(primary_player)) {
          secondary_player_options = Array.from($scope.playOptions.TEAM2_PLAYERS);
        }
        if (newValues[1] == 'sub_in'){
          secondary_player_options = secondary_player_options.filter((o) => primary_player != o.code);
        }
      }
      else {
        secondary_player_options = $scope.playOptions.PLAYERS;
      }

      if (form_type == "main_form") {
        $scope.playOptions.SECONDARY_PLAYERS = secondary_player_options;
      }
      else {
        $scope.playOptions.EDIT_SECONDARY_PLAYERS = secondary_player_options;
      }
    };

    // Restrict secondary player options based on the player in the primary play and the chosen secondary play
    $scope.$watchGroup(['play.primary_player', 'play.secondary_play'], function(newValues, oldValues) {
      restrictSecondaryPlayer('main_form', $scope.play.primary_player, newValues, oldValues)
    });

    $scope.$watchGroup(['editplay.primary_player', 'editplay.secondary_play'], function(newValues, oldValues) {
      restrictSecondaryPlayer('edit_form', $scope.editplay.primary_player, newValues, oldValues)
    });

    function restrictAssistPlayer(form_type, primary_player, newValues, oldValues) {
      var assist_player_options;

      if (!primary_player) {
        assist_player_options = $scope.playOptions.PLAYERS;
      }
      else if (['asts', 'pot_ast'].includes(newValues[1])) {
        if ($scope.team1_player_ids.includes(primary_player)) {
          assist_player_options = Array.from($scope.playOptions.TEAM1_PLAYERS);
        }
        else if ($scope.team2_player_ids.includes(primary_player)) {
          assist_player_options = Array.from($scope.playOptions.TEAM2_PLAYERS);
        }
        assist_player_options = assist_player_options.filter((o) => primary_player != o.code);
      }
      else {
        assist_player_options = $scope.playOptions.PLAYERS;
      }

      assist_player_options = remove_team_player_options(assist_player_options)

      if (form_type == "main_form") {
        $scope.playOptions.ASSIST_PLAYERS = assist_player_options;
      }
      else {
        $scope.playOptions.EDIT_ASSIST_PLAYERS = assist_player_options;
      }
    };
    // Restrict assist player options based on the player in the primary play
    $scope.$watchGroup(['play.primary_player', 'play.assist'], function(newValues, oldValues) {
      restrictAssistPlayer("main_form", $scope.play.primary_player, newValues, oldValues);
    });

    $scope.$watchGroup(['editplay.primary_player', 'editplay.assist'], function(newValues, oldValues) {
      restrictAssistPlayer("edit_form", $scope.editplay.primary_player, newValues, oldValues);
    });

    function getPlays(){
      GameService.getGamePlays($routeParams['groupId'], $routeParams['gameid']).then(function (response){
        $scope.plays = _.reverse(_.sortBy(response, 'time'));
        calculateScore();

        $scope.plays = $scope.plays.map(function myFunction(play) {
          if (play.primary_player && play.primary_player.is_real_player === false){
            play.primary_player.first_name = play.primary_player.first_name + " (T)";
          }
          else if (play.primary_player && play.primary_player.id === 5){
            play.primary_player.first_name = $scope.game.team1_name + " (T)";
          } else if (play.primary_player && play.primary_player.id === 6){
            play.primary_player.first_name = $scope.game.team2_name + " (T)";
          }

          if (play.secondary_player && play.secondary_player.is_real_player === false){
            play.secondary_player.first_name = play.secondary_player.first_name + " (T)";
          }
          else if (play.secondary_player && play.secondary_player.id === 5){
            play.secondary_player.first_name = $scope.game.team1_name + " (T)"
          } else if (play.secondary_player && play.secondary_player.id === 6){
            play.secondary_player.first_name = $scope.game.team2_name + " (T)"
          }
          
          if (play.assist_player && play.assist_player.is_real_player === false){
            play.assist_player.first_name = play.assist_player.first_name + " (T)";
          }
          else if (play.assist_player && play.assist_player.id === 5){
            play.assist_player.first_name = $scope.game.team1_name + " (T)"
          } else if (play.assist_player && play.assist_player.id === 6){
            play.assist_player.first_name = $scope.game.team2_name + " (T)"
          }
          return play;
        })
      }, function(response){
      });
    }

    var modalInstance;
    function fillEditForm(playid, index, parentSelector) {
      var parentElem = parentSelector ?
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      GameService.getPlay($routeParams.groupId, playid).then(function (response){
        $scope.editplaymessage = "";
        $scope.editplayindex = index;
        $scope.editplay = response;
        $scope.editplay.primary_player = response.primary_player.id;
        $scope.editplay.secondary_player = (response.secondary_player && response.secondary_player.id) ? response.secondary_player.id : '';
        $scope.editplay.assist_player = (response.assist_player && response.assist_player.id)? response.assist_player.id : '';
        $scope.editplay.top_play_players = response.top_play_players;
        modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'static/partials/play_form_modal2.html',
          size: 'lg',
          scope: $scope
        });
        modalInstance.result.then(function (selectedItem) {
          $scope.selected = selectedItem;
        }, function () {});
      });
    }

    function closeEditForm() {
      modalInstance.close();
    }

    function calculateScore() {
      $scope.team1_score = 0;
      $scope.team2_score = 0;
      var scoring_plays = ['fgm', 'threepm', 'ftm'];
      var score_type = $scope.game.score_type;
      var score_to_add;
      _.forEach($scope.plays, function(play){
        if (scoring_plays.includes(play.primary_play)) {
          if (play.primary_play === 'ftm') {
            score_to_add = 1;
          }
          else if (score_type === '2and3'){
            score_to_add = (play.primary_play == 'fgm') ? 2 : 3;
          }
          else {
            score_to_add = (play.primary_play == 'fgm') ? 1 : 2;
          }
          if (_.find($scope.game.team1_members, function(player) {return play.primary_player.id === player.id;})) {
            $scope.team1_score += score_to_add;
          } else {
            $scope.team2_score += score_to_add;
          }
        }
      });
    }

    function updatePlay(play) {
      $scope.playsErrorMessage = "";

      if ($scope.ctrlrState === 'without_video') {
        play.time = calculateTime(play, $scope.plays);
      }

      if (play.secondary_player && !play.secondary_play){
        play.secondary_player = "";
        play.secondary_play = "";
      }
      if (play.assist_player && !play.assist){
        play.assist_player = '';
        play.assist = "";
      }
      if (play.hasOwnProperty('top_play_rank') && !play.top_play_rank){
        play.top_play_rank = "";
      }

      $scope.editplaymessage = "Saving Play...."
      GameService.updatePlay($routeParams.groupId, play).then(function(response){
        $scope.editplaymessage = "Saved!";
        $scope.plays[$scope.editplayindex] = response;
        $scope.plays = _.reverse(_.sortBy($scope.plays, 'time'));
        calculateScore();
        GameService.calculateStatlines($routeParams.groupId, $scope.game.id).then(function(response){}, function(response){
          if (response.data.subErrorMessage) {
            $scope.playsErrorMessage = response.data.subErrorMessage;
          }
          else {
            $scope.playsErrorMessage = "An error occurred while calculating stats. Please double check the plays entered make sense.";
          }
        });
        $timeout(function() {
          modalInstance.close();
        }, 1500);
      }, function(response){
        $scope.editplaymessage = "Failed to save play. Try checking each field again";
      });
    }

    function createPlay(play) {
        $scope.playsErrorMessage = "";
        play.game = $scope.game.id
        if (play.secondary_player && !play.secondary_play){
          delete play.secondary_player;
        }
        if (!play.secondary_player && play.secondary_play){
          delete play.secondary_play;
        }
        if (play.assist_player && !play.assist){
          delete play.assist_player;
        }
        if (!play.assist_player && play.assist){
          delete play.assist;
        }
        if (play.secondary_play == null){
          delete play.secondary_play;
          delete play.secondary_player;
        }
        if (play.assist == null){
          delete play.assist;
          delete play.assist_player;
        }
        $scope.message = "Adding Play...."
        GameService.createPlay($routeParams.groupId, play).then(function(response){
          $scope.message = "Successfully Added";
          $scope.play = {};
          $scope.plays.push(response);
          $scope.plays = _.reverse(_.sortBy($scope.plays, 'time'));
          calculateScore();
          GameService.calculateStatlines($routeParams.groupId, $scope.game.id).then(function(response){}, function(response){
            if (response.data.subErrorMessage) {
              $scope.playsErrorMessage = response.data.subErrorMessage;
            }
            else {
              $scope.playsErrorMessage = "An error occurred while calculating stats. Please double check the plays entered.";
            }
          });
          $scope.playform.play.$setUntouched();
          $timeout(function() {
            $anchorScroll("playeranchor");
          }, 1000);
          focus('timestamp');
        }, function(response){
          $scope.message = "Failed to add play";
        });
      }

    function deletePlay(playid) {
        $scope.playsErrorMessage = "";
        GameService.deletePlay($routeParams.groupId, playid).then(function(response){
          _.remove($scope.plays, function(play) { return play.id === playid; });
          calculateScore();
          GameService.calculateStatlines($routeParams.groupId, $scope.game.id).then(function(response){}, function(response){
            if (response.data.subErrorMessage) {
              $scope.playsErrorMessage = response.data.subErrorMessage;
            }
            else {
              $scope.playsErrorMessage = "An error occurred while calculating stats. Please double check the plays entered.";
            }
          });
          calculateScore();
          modalInstance.close();
        });
    }

    function calculateTime(play, plays) {
      if (plays.length === 0) {
        return 10;
      }

      if (play.id) {
        let play_index = plays.findIndex((pobj) => pobj.id == play.id);
        var previous_play = plays[play_index+1];
      } else {
        var previous_play = plays[0];
      }

      var previous_play_seconds = parseInt(previous_play.time.split(":")[0] * 3600) +
                              parseInt(previous_play.time.split(":")[1] * 60) +
                              parseInt(previous_play.time.split(":")[2])

      var increment = 0;
      if (['fgm', 'threepm', 'fga', 'threepa'].indexOf(play.primary_play) > -1) {
        if (play.is_fastbreak || play.is_putback) {
          //TODO: Need to allow both of these
          if (play.is_putback) {
            increment = 6;
          }
          if (play.is_fastbreak) {
            increment = 10;
          }
        }
        else {
          increment = 11;
        }
      }
      else if (['to', 'pf'].indexOf(play.primary_play) > -1) {
        increment = 5;
      }
      else if (['sub_out'].indexOf(play.primary_play) > -1 && previous_play.primary_play !== 'sub_out') {
        increment = 5;
      }
      var new_play_time = previous_play_seconds + increment;

      return new_play_time;

    }

    function changeState(state) {
      $scope.ctrlrState = state;
    }

    // YouTube player logic.
    $scope.specifiedTime = null;
    $scope.ytplayer = null;
    $scope.playModal = {
      'grabTime': grabTime
    }

    $scope.playerReady = function() {
      $scope.ytplayer = new YT.Player('ytplayer', {
        videoId: $scope.game.youtube_id,
      });
    }

    $scope.$watch("game.youtube_id", function(newValue,oldValue){
      $scope.playerReady()
     });

    $scope.$on('onYouTubePlayerAPIReady', function() {
      // This empty block is ran when the youtube api is ready.
      // leaving it here just so I dont forget its purpose if needed.
    });

    function grabTimestamp(offset) {
      var formattedTime, seconds
      if (offset) {
        seconds = $scope.ytplayer.getCurrentTime() - offset
        if (seconds < 0) {
          seconds = 0;
        }
      }
      else{
        seconds = $scope.ytplayer.getCurrentTime()
      }

      var hours = '' + Math.floor(seconds / 3600)
      if (hours.length < 2){
        hours = '0' + hours;
      }
      var minutes = '' + Math.floor(seconds / 60) % 60
      if (minutes.length < 2){
        minutes = '0' + minutes;
      }
      seconds = '' + Math.floor(seconds % 60)
      if (seconds.length < 2){
        seconds = '0' + seconds;
      }
      formattedTime = hours + ':' + minutes + ":" + seconds
      return formattedTime
    }

    function grabTime() {
      let timestamp = grabTimestamp();
      $scope.play.time = timestamp;
    }

    $scope.clearRanks = function() {
      $scope.editplay['top_play_rank'] = "";
      $scope.editplay['description'] = "";
      $scope.editplay['top_play_players'] = [];
    }

    function setMetaTags() {
      $scope.page_title = `Add Plays | ${$scope.game.group.name}`
      $scope.og_title = `Add Plays | ${$scope.game.group.name}`
      $scope.og_description = 'Use the form to add plays to a game.'
    }

    function seekToTime(timestamp, offset=-4) {
      var split_time = timestamp.split(':');
      var seconds = parseInt(split_time[0]) * 3600;
      seconds += parseInt(split_time[1]) * 60;
      seconds += parseInt(split_time[2]);
      seconds += offset;
      if (seconds < 0) {
        seconds = 0;
      }
      $scope.ytplayer.playVideo();
      $scope.ytplayer.seekTo(seconds);
      $anchorScroll("playeranchor");
    };

    $scope.pauseVideoToggle = function() {
      if ($scope.ytplayer.getPlayerState() == 1) {
        $scope.ytplayer.pauseVideo();
      }
      else {
        $scope.ytplayer.playVideo();
      }
    }

    window.addEventListener('keydown', function(e){
      if (e.key in $scope.pressedKeysMap || e.key == " ") {
        $scope.pressedKeysMap[e.key] = true;
      }
      
      // if ctrl and t are pressed grab time and focus timestamp field
      if (($scope.pressedKeysMap["Control"] || $scope.pressedKeysMap['Meta']) && $scope.pressedKeysMap["Shift"] && $scope.pressedKeysMap["ArrowDown"]) {
          e.preventDefault();
          $scope.playModal.grabTime();
          $scope.$apply();
          focus('timestamp');
      }
      // if ctrl and space are pressed pause video
      else if (($scope.pressedKeysMap["Control"] || $scope.pressedKeysMap['Meta']) && $scope.pressedKeysMap["Shift"] && $scope.pressedKeysMap[" "]) {
        e.preventDefault();
        $scope.pauseVideoToggle();
        $anchorScroll("playeranchor");
        e.stopPropagation();
      }
      // if ctrl and left arrow are pressed seek backward 5 seconds
      else if (($scope.pressedKeysMap["Control"] || $scope.pressedKeysMap['Meta']) && $scope.pressedKeysMap["Shift"] && $scope.pressedKeysMap["ArrowLeft"]) {
        e.preventDefault();
        $scope.seekToTime(grabTimestamp(), -5);
        $scope.triggerBackwardSeekMessage();
        $scope.$apply();
        $anchorScroll("playeranchor");
      }
      // if ctrl and right arrow are pressed seek forward 5 seconds
      else if (($scope.pressedKeysMap["Control"] || $scope.pressedKeysMap['Meta']) && $scope.pressedKeysMap["Shift"] && $scope.pressedKeysMap["ArrowRight"]) {
        e.preventDefault();
        $scope.seekToTime(grabTimestamp(), 5);
        $scope.triggerForwardSeekMessage()
        $scope.$apply();
        $anchorScroll("playeranchor");
      }
      else if (($scope.pressedKeysMap["Control"] || $scope.pressedKeysMap['Meta']) && $scope.pressedKeysMap["Shift"] && $scope.pressedKeysMap["Enter"]) {
        e.preventDefault();
        $scope.createPlay($scope.play);
        $scope.$apply();
        $anchorScroll("playeranchor");
      }
      
    });

    window.addEventListener('keyup', function(e){
      if (e.key in $scope.pressedKeysMap) {
        $scope.pressedKeysMap[e.key] = false;
      }
    });

    function triggerBackwardSeekMessage() {
      $scope.showBackwardSeekMessage = true;
      $timeout(function() {
         $scope.showBackwardSeekMessage = false;
         $scope.$apply();
      }, 500);
    }

    function triggerForwardSeekMessage() {
      $scope.showForwardSeekMessage = true;
      $timeout(function() {
         $scope.showForwardSeekMessage = false;
         $scope.$apply();
      }, 500);
    }

};
