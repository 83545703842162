'use strict';

angular.module('saturdayBall')

.controller('GameController', GameController);

GameController.$inject = ['$rootScope', '$scope', '$window', '$routeParams', 'GameService', 'GroupService', 'Session', 'RoleHelper',
  '$anchorScroll', 'playOptions', '$timeout', '$uibModal']

function GameController($rootScope, $scope, $window, $routeParams, GameService, GroupService, Session, RoleHelper,
  $anchorScroll, playOptions, $timeout, $uibModal) {

  $scope.adv_box_scores = null;
  $scope.box_scores = null;
  $scope.ctrlrState = "with_video";
  $scope.closeEditForm = closeEditForm;
  $scope.filters = {
    primary_play: '',
    secondary_play: '',
    assist: '',
    primary_player: {'id': ''},
    secondary_player: {'id': ''},
    assist_player: {'id': ''}
  };
  $scope.filterFormVisible = false;
  $scope.game = {};
  $scope.group = null;
  $scope.groupId = $routeParams['groupId'];
  $scope.importBoxScoreMessage = '';
  $scope.importBoxScoreMessageStatus = null;
  $scope.reloadMessage = "";
  $scope.playOptions = playOptions;
  $scope.player = null;
  $scope.PLAYERS = [];
  $scope.search = {};
  $scope.seekToTime = seekToTime;
  $scope.showHideFilter = showHideFilter;
  $scope.showYTPlayer = true;
  $scope.tbd_teams_exist = true;
  $scope.user = Session.currentUser();

  /* Update play scope variables */
  $scope.deletePlay = deletePlay;
  $scope.editplay = {};
  $scope.editplaymessage = '';
  $scope.fillEditForm = fillEditForm;
  $scope.playModal = {
    'grabTime': grabTime
  }
  $scope.updatePlay = updatePlay;
  /* end of update play variables */

  ///////////////////

  init();

  function init() {

    GameService.getGame($routeParams['groupId'], $routeParams['gameId']).then(function(response) {
      $scope.game = response;
      
      setMetaTags();

      if ($scope.game.group.is_league && $scope.game.season_id != $scope.game.group.active_season_id) {
        $scope.season_slug_param = $scope.game.season.slug;
      }

      if ($scope.game.group.is_league === false) {
        // impossible to have tbd teams in a non-league game
        $scope.tbd_teams_exist = false;
      }

      if (!$scope.game.youtube_id) {
        $scope.ctrlrState = "without_video";
        $scope.showYTPlayer = false;
      }
      
      if ($scope.game.title) {
        $rootScope.title = $scope.game.title + ' - SaturdayBall.com'
      } else {
        $rootScope.title = `${$scope.game.team1_name} vs ${$scope.game.team2_name} - SaturdayBall.com`
      }
      //check if this game is a aprt of the group, if not redirect.
      if ($scope.game.group.id.toString() !== $routeParams['groupId']){
        window.location.replace('/group/' + $routeParams['groupId'] + '/games');
        return;
      }
      var params = {start_time: $scope.game.start_time}
      GameService.getGames($routeParams['groupId'], params).then(function(response) {
        var games = response;
        for (var i = 0; i < games.length; i++) {
          if (games[i].id === $scope.game.id) {
            $scope.next_game = i+1 < games.length ? games[i+1] : null;
            $scope.prev_game = i-1 >= 0 ? games[i-1] : null;
          }
        }
      })
      // Grab the ids of the players on each team
      $scope.team1_player_ids = $scope.game.team1_members.map((obj) => obj.id);
      $scope.team2_player_ids = $scope.game.team2_members.map((obj) => obj.id);

      // Initialize the player options for each team with the 'team' player first
      set_team_player_options();

      // Loop through all the players and add them as options for the team they play for.
      angular.forEach($scope.game.team1_members, function(value, key) {
        if (value.id !== 5 && value.id !== 6 && value.is_real_player !== false){
            $scope.playOptions.TEAM1_PLAYERS.push({'code':value.id, 'name': value.first_name + ' ' + value.last_name, 'isRealPlayer': value.is_real_player});
        }
      });
      angular.forEach($scope.game.team2_members, function(value, key) {
        if (value.id !== 5 && value.id !== 6 && value.is_real_player !== false){
            $scope.playOptions.TEAM2_PLAYERS.push({'code':value.id, 'name': value.first_name + ' ' + value.last_name, 'isRealPlayer': value.is_real_player});
        }
      });

      // Set the Primary Player option by combining the two teams
      $scope.playOptions.PLAYERS = $scope.playOptions.TEAM1_PLAYERS.concat($scope.playOptions.TEAM2_PLAYERS);

      $scope.playOptions.EDIT_SECONDARY_PLAY = $scope.playOptions.ALL_SECONDARY_PLAYS;
      $scope.playOptions.EDIT_SECONDARY_PLAYERS = $scope.playOptions.PLAYERS;
      $scope.playOptions.EDIT_ASSIST_PLAY = $scope.playOptions.ALL_ASSIST_PLAYS;
      $scope.playOptions.EDIT_ASSIST_PLAYERS = $scope.playOptions.PLAYERS;
      $scope.playOptions.EDIT_ASSIST_PLAYERS = remove_team_player_options($scope.playOptions.PLAYERS);
      $scope.playOptions.EDIT_HIGHLIGHT_PLAYERS = remove_team_player_options($scope.playOptions.PLAYERS);

      if (!$scope.game.group.ft_enabled) {
        $scope.playOptions.PRIMARY_PLAY = playOptions.PRIMARY_PLAY.filter((el) => el.code != "ftm" && el.code != "fta");
      }
      if (!$scope.game.group.tf_enabled) {
        $scope.playOptions.PRIMARY_PLAY = playOptions.PRIMARY_PLAY.filter((el) => el.code != "tf");
      }

      $scope.$on('youtube.player.ready', function($event, player) {
        $scope.player = player;
      })

      // set season_slug_param if non-active season
      if ($scope.game.season && $scope.game.season.is_active === false) {
        $rootScope.season_slug_param = $scope.game.season.slug;
      }

    }, function() {});

    GameService.getGameBoxScore($routeParams.groupId, $routeParams['gameId']).then(function(response) {
      $scope.box_scores = response;
    })

    GameService.getGameAdvBoxScore($routeParams.groupId, $routeParams['gameId']).then(function(response) {
      $scope.adv_box_scores = response;
    })

  };

  function setMetaTags() {
    $scope.og_url = `https://saturdayball.com/group/${$scope.game.group.id}/game/${$scope.game.id}`;
    $scope.og_image = `https://saturdayball.com/media/saturdayball_logo.png`;
    $scope.page_title = ($scope.game.title || `${$scope.game.team1_name} vs ${$scope.game.team2_name}`) + ` | ${$scope.game.group.name}`
    $scope.og_title = ($scope.game.title || `${$scope.game.team1_name} vs ${$scope.game.team2_name}`) + ` | ${$scope.game.group.name}`
    let date = new Date($scope.game.aware_start_time);
    if ($scope.game.title) {
      $scope.og_description = `${date.toDateString()} | ${$scope.game.team1_name} vs ${$scope.game.team2_name}`
    } else {
      $scope.og_description = `${date.toDateString()}`
    }
  }

  function set_team_player_options() {
    // initializes the player options for each team by finding the 'team' player first.
    // additionally the 'team' players are returned for further processing
    let team1_option, team2_option;
    if ($scope.game.group.is_league) {
      if ($scope.game.team1) {
        // loop through team1_members to find the team player and add them to team1_option
        angular.forEach($scope.game.team1_members, function(value, key) {
          if (value.is_real_player === false){
            team1_option = {'code': value.id, 'name': $scope.game.team1_name + " (Team)", 'isRealPlayer': false};
            return;
          }
        });
      } else {
        team1_option = {'code': 0, 'name': $scope.game.team1_name + " (Team)"};
      }
      $scope.playOptions.TEAM1_PLAYERS = [team1_option];

      if ($scope.game.team2) {
        // loop through team2_members to find the team player and add them to team2_option
        angular.forEach($scope.game.team2_members, function(value, key) {
          if (value.is_real_player === false){
            team2_option = {'code': value.id, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
            return;
          }
        });
      } else {
        team2_option = {'code': 0, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
      }
      $scope.playOptions.TEAM2_PLAYERS = [team2_option];
    } else {
      team1_option = {'code': 5, 'name': $scope.game.team1_name + " (Team)", 'isRealPlayer': false};
      team2_option = {'code': 6, 'name': $scope.game.team2_name + " (Team)", 'isRealPlayer': false};
      $scope.playOptions.TEAM1_PLAYERS = [team1_option];
      $scope.playOptions.TEAM2_PLAYERS = [team2_option];
    }

    if ($scope.game.team2_name !== "TBD" && $scope.game.team1_name !== "TBD") {
      // if either team is TBD, then we want to disable add plays button
      $scope.tbd_teams_exist = false;
    }
  }

  function remove_team_player_options(players) {
    let newPlayersList = [];
    angular.forEach(players, function(value, key){
      if (value.isRealPlayer === true) {
        newPlayersList.push(value);
      }
    })
    return newPlayersList
  }

  function restrictSecondaryPlay(form_type, newValue, oldValue) {
    var secondary_play_options, assist_play_options;

    if (['fga', 'threepa', 'fta'].includes(newValue)) {
      secondary_play_options = [
        {'code': 'dreb', 'name': 'DREB'},
        {'code': 'oreb', 'name': 'OREB'},
      ];
    }
    else if (['fgm', 'threepm', 'ftm', 'misc'].includes(newValue)) {
      secondary_play_options = [];
    }
    else if (['blk'].includes(newValue)) {
      secondary_play_options = [
        {'code': 'ba', 'name': 'Block Against'}
      ];
      $scope.editplay.secondary_play = 'ba';
    }
    else if (['to'].includes(newValue)) {
      secondary_play_options = [
        {'code': 'stls', 'name': 'STL'}
      ];
    }
    else if (['pf'].includes(newValue)) {
      secondary_play_options = [
        {'code': 'fd', 'name': 'Foul Drawn'}
      ];
      $scope.editplay.secondary_play = 'fd';
    }
    else if (['sub_out'].includes(newValue)) {
      secondary_play_options = [
        {'code': 'sub_in', 'name': 'SUB IN'}
      ];
      $scope.editplay.secondary_play = 'sub_in';
    }
    else {
      secondary_play_options = $scope.playOptions.ALL_SECONDARY_PLAYS;
    }

    // Handle assist options
    if (['fgm', 'threepm'].includes(newValue)) {
      assist_play_options = [{'code': 'asts', 'name': 'Assist'}]
    }
    else if (['fga', 'threepa'].includes(newValue)) {
      assist_play_options = [{'code': 'pot_ast', 'name': 'Potential Assist'}]
    }
    else if (['ftm', 'fta', 'blk', 'to', 'pf', 'sub_out', 'misc'].includes(newValue)){
      assist_play_options = []
    }
    else {
      assist_play_options = $scope.playOptions.ALL_ASSIST_PLAYS;
    }

    if (form_type == "main_form") {
      $scope.playOptions.SECONDARY_PLAY = secondary_play_options;
      $scope.playOptions.ASSIST_PLAY = assist_play_options;
    }
    else {
      $scope.playOptions.EDIT_SECONDARY_PLAY = secondary_play_options;
      $scope.playOptions.EDIT_ASSIST_PLAY = assist_play_options;
    }
  };

  // Restrict secondary and assist play options in the edit play form based on the chosen primary play 
  $scope.$watch('editplay.primary_play', function(newValue, oldValue) {
    restrictSecondaryPlay('edit_form', newValue, oldValue)
  });

  function restrictSecondaryPlayer(form_type, primary_player, newValues, oldValues) {
    var secondary_player_options;
    
    if (!primary_player) {
      secondary_player_options = $scope.playOptions.PLAYERS;
    }
    else if (['dreb', 'stls', 'ba', 'fd'].includes(newValues[1])) {
      if ($scope.team1_player_ids.includes(primary_player)) {
        secondary_player_options = $scope.playOptions.TEAM2_PLAYERS;
      }
      else if ($scope.team2_player_ids.includes(primary_player)) {
        secondary_player_options = $scope.playOptions.TEAM1_PLAYERS;
      }
    }
    else if (['oreb', 'sub_in'].includes(newValues[1])) {
      if ($scope.team1_player_ids.includes(primary_player)) {
        secondary_player_options = Array.from($scope.playOptions.TEAM1_PLAYERS);
      }
      else if ($scope.team2_player_ids.includes(primary_player)) {
        secondary_player_options = Array.from($scope.playOptions.TEAM2_PLAYERS);
      }
      if (newValues[1] == 'sub_in'){
        secondary_player_options = secondary_player_options.filter((o) => primary_player != o.code);
      }
    }
    else {
      secondary_player_options = $scope.playOptions.PLAYERS;
    }

    if (form_type == "main_form") {
      $scope.playOptions.SECONDARY_PLAYERS = secondary_player_options;
    }
    else {
      $scope.playOptions.EDIT_SECONDARY_PLAYERS = secondary_player_options;
    }
  };

  // Restrict secondary player options based on the player in the primary play and the chosen secondary play
  $scope.$watchGroup(['editplay.primary_player', 'editplay.secondary_play'], function(newValues, oldValues) {
    restrictSecondaryPlayer('edit_form', $scope.editplay.primary_player, newValues, oldValues)
  });

  function restrictAssistPlayer(form_type, primary_player, newValues, oldValues) {
    var assist_player_options;

    if (!primary_player) {
      assist_player_options = $scope.playOptions.PLAYERS;
    }
    else if (['asts', 'pot_ast'].includes(newValues[1])) {
      if ($scope.team1_player_ids.includes(primary_player)) {
        assist_player_options = Array.from($scope.playOptions.TEAM1_PLAYERS);
      }
      else if ($scope.team2_player_ids.includes(primary_player)) {
        assist_player_options = Array.from($scope.playOptions.TEAM2_PLAYERS);
      }
      assist_player_options = assist_player_options.filter((o) => primary_player != o.code);
    }
    else {
      assist_player_options = $scope.playOptions.PLAYERS;
    }

    if (form_type == "main_form") {
      $scope.playOptions.ASSIST_PLAYERS = assist_player_options;
    }
    else {
      $scope.playOptions.EDIT_ASSIST_PLAYERS = assist_player_options;
    }
  };
  // Restrict assist player options based on the player in the primary play
  $scope.$watchGroup(['editplay.primary_player', 'editplay.assist'], function(newValues, oldValues) {
    restrictAssistPlayer("edit_form", $scope.editplay.primary_player, newValues, oldValues);
  });


  $scope.$watch("game.id", function(newValue,oldValue){
    getPlays();
  });

  function getPlays() {
    GameService.getGamePlays($routeParams['groupId'], $routeParams['gameId']).then(function(response) {
      var plays = _.orderBy(response, ['time'], ['asc']);

      $scope.plays = plays.map(function myFunction(play) {
        if (play.primary_player && play.primary_player.is_real_player === false){
          play.primary_player.first_name = play.primary_player.first_name + " (T)";
        }
        else if (play.primary_player && play.primary_player.id === 5){
          play.primary_player.first_name = $scope.game.team1_name + " (T)";
        } else if (play.primary_player && play.primary_player.id === 6){
          play.primary_player.first_name = $scope.game.team2_name + " (T)";
        }

        if (play.secondary_player && play.secondary_player.is_real_player === false){
          play.secondary_player.first_name = play.secondary_player.first_name + " (T)";
        }
        else if (play.secondary_player && play.secondary_player.id === 5){
          play.secondary_player.first_name = $scope.game.team1_name + " (T)"
        } else if (play.secondary_player && play.secondary_player.id === 6){
          play.secondary_player.first_name = $scope.game.team2_name + " (T)"
        }
        
        if (play.assist_player && play.assist_player.is_real_player === false){
          play.assist_player.first_name = play.assist_player.first_name + " (T)";
        }
        else if (play.assist_player && play.assist_player.id === 5){
          play.assist_player.first_name = $scope.game.team1_name + " (T)"
        } else if (play.assist_player && play.assist_player.id === 6){
          play.assist_player.first_name = $scope.game.team2_name + " (T)"
        }
        return play;
      })
    }, function(response) {
      console.log('Plays failed: ', response);
    })
  }

  var jumpToPlayerAnchor = function() {
    $anchorScroll("playeranchor");
  }

  function seekToTime(timestamp) {
    var split_time = timestamp.split(':');
    var seconds = parseInt(split_time[0]) * 3600;
    seconds += parseInt(split_time[1]) * 60;
    seconds += parseInt(split_time[2]);
    seconds -= 4;
    $scope.player.playVideo();
    $scope.player.seekTo(seconds);
    jumpToPlayerAnchor();
  };

  function showHideFilter() {
    $scope.filterFormVisible = $scope.filterFormVisible ? false : true;
  };

  $scope.$watch('filters', function () {
    $scope.search = {};
    if($scope.filters.primary_play) {
      $scope.search.primary_play = $scope.filters.primary_play;
    }
    if($scope.filters.secondary_play) {
      $scope.search.secondary_play = $scope.filters.secondary_play;
    }
    if($scope.filters.assist_play) {
      $scope.search.assist_play = $scope.filters.assist_play;
    }
    if($scope.filters.primary_player.id) {
      $scope.search.primary_player = {
        id: $scope.filters.primary_player.id
      }
    }
    if($scope.filters.secondary_player.id) {
      $scope.search.secondary_player = {
        id: $scope.filters.secondary_player.id
      }
    }
    if($scope.filters.assist_player.id) {
      $scope.search.assist_player = {
        id: $scope.filters.assist_player.id
      }
    }
  }, true);

  /* Update Play logic */
  var modalInstance;
  function fillEditForm(playid) {
    GameService.getPlay($routeParams.groupId, playid).then(function (response){
      $scope.editplaymessage = "";
      $scope.editplay = response;
      $scope.editplay.primary_player = response.primary_player.id;
      $scope.editplay.secondary_player = (response.secondary_player && response.secondary_player.id) ? response.secondary_player.id : '';
      $scope.editplay.assist_player = (response.assist_player && response.assist_player.id)? response.assist_player.id : '';
      $scope.editplay.top_play_players = response.top_play_players;
      modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'static/partials/play_form_modal2.html',
        size: 'lg',
        scope: $scope
      });
      modalInstance.result.then(function (selectedItem) {
        $scope.selected = selectedItem;
      }, function () {});
    });
  }

  function closeEditForm() {
    modalInstance.close();
  }

  function updatePlay(play) {
    if (play.secondary_player && !play.secondary_play){
      play.secondary_player = "";
      play.secondary_play = "";
    }
    if (play.assist_player && !play.assist){
      play.assist_player = '';
      play.assist = "";
    }
    if (play.hasOwnProperty('top_play_rank') && !play.top_play_rank){
      play.top_play_rank = "";
    }

    $scope.editplaymessage = "Saving Play...."
    GameService.updatePlay($routeParams.groupId, play).then(function(response){
      $scope.editplaymessage = "Successfully saved.  Game stats are being recalculated.  Refresh the page in a bit to see your changes.";
      getPlays();
      GameService.calculateStatlines($routeParams.groupId, $scope.game.id).then(function(response){});
      $timeout(function() {
        modalInstance.close();
      }, 1500);
    }, function(response){
      console.log(response);
      $scope.editplaymessage = "Failed to save play";
    });
  }

  function deletePlay(playid) {
      GameService.deletePlay($routeParams.groupId, playid).then(function(response){
        _.remove($scope.plays, function(play) {
          return play.id === playid;
        });
        GameService.calculateStatlines($routeParams.groupId, $scope.game.id).then(function(response){});
        modalInstance.close();
      });
  }

  // Upload Box Score CSV Function
  $scope.add = function() {
    if (confirm("Are you sure you want to overwrite the box score with your .csv?")){
      
      var f = document.getElementById('csv_file').files[0],
          r = new FileReader();

      r.onloadend = function(e) {
        var fd = new FormData();
        fd.append('csv_file', f);
        var data = e.target.result;
        $scope.importBoxScoreMessageStatus = null;
        $scope.importBoxScoreMessage = "Processing..."
        GameService.importBoxScoreCSV($routeParams.groupId, $routeParams.gameId, fd).then(function(response){
          document.getElementById('csv_file').value = null;
          $scope.importBoxScoreMessageStatus = true;
          $scope.importBoxScoreMessage = response['message'];
          $timeout(function() {$window.location.reload()}, 2000);
        }, function(response){
          document.getElementById('csv_file').value = null;
          $scope.importBoxScoreMessageStatus = false;
          $scope.importBoxScoreMessage = response['data']['message'];
        });
        
        //send your binary data via $http or $resource or do anything else with it

      }

      r.readAsBinaryString(f);
    }
  }

  // YouTube player logic.
  $scope.ytplayer = null;
  $scope.playModal = {
    'grabTime': grabTime
  }

  $scope.playerReady = function() {
    $scope.ytplayer = new YT.Player('ytplayer', {
      videoId: $scope.game.youtube_id,
    });
  }

    $scope.$watch("game.youtube_id", function(newValue,oldValue){
      $scope.playerReady()
    });

    function seekToTime(timestamp) {
      var split_time = timestamp.split(':');
      var seconds = parseInt(split_time[0]) * 3600;
      seconds += parseInt(split_time[1]) * 60;
      seconds += parseInt(split_time[2]);
      seconds -= 4;
      $scope.ytplayer.playVideo();
      $scope.ytplayer.seekTo(seconds);
      $anchorScroll("playeranchor");
    };

  function grabTime(offset) {
    var formattedTime, seconds
    if (offset) {
      seconds = $scope.ytplayer.getCurrentTime() - offset
      if (seconds < 0) {
        seconds = 0;
      }
    }
    else{
      seconds = $scope.ytplayer.getCurrentTime()
    }

    var hours = '' + Math.floor(seconds / 3600)
    if (hours.length < 2){
      hours = '0' + hours;
    }
    var minutes = '' + Math.floor(seconds / 60) % 60
    if (minutes.length < 2){
      minutes = '0' + minutes;
    }
    seconds = '' + Math.floor(seconds % 60)
    if (seconds.length < 2){
      seconds = '0' + seconds;
    }
    formattedTime = hours + ':' + minutes + ":" + seconds
    $scope.editplay.time = formattedTime;
  }
};
