'use strict';

angular.module('saturdayBall').config(['$locationProvider', '$routeProvider', function($locationProvider, $routeProvider) {
  var routeResolver = {
    preLoad: function (routeResolver){
      return routeResolver()
    }
  };

  $routeProvider
      .when("/", {
        title: "Saturday Ball - Pick Up Basketball Stat Tracker",
        templateUrl: "static/views/home.html",
        controller: "HomeController",
        resolve: routeResolver
      })
      .when("/contact/", {
        title: 'Contact - SaturdayBall.com',
        templateUrl: "static/views/contact.html",
        controller: "ContactController",
        resolve: routeResolver
      })
      .when("/group/:groupId/games/", {
        title: 'Games - SaturdayBall.com',
        templateUrl: "static/views/games.html",
        controller: 'GamesController',
        resolve: routeResolver,
        activetab: 'games'
      })
      .when("/group/:groupId/games/:gameId/", {
        title: 'Game - SaturdayBall.com',
        templateUrl: "static/views/game.html",
        controller: 'GameController',
        resolve: routeResolver,
        activetab: 'games'
      })
      .when("/group/:groupId/schedule/:gameId/", {
        title: 'Game - SaturdayBall.com',
        templateUrl: "static/views/game.html",
        controller: 'GameController',
        resolve: routeResolver,
        activetab: 'schedule',
      })
      .when("/group/:groupId/games/:gameid/add-plays/", {
        title: 'Add Plays - SaturdayBall.com',
        templateUrl: "static/views/add_plays.html",
        controller: 'AddPlaysController',
        resolve: routeResolver,
        activetab: 'games'
      })
      .when("/group/:groupId/schedule/:gameid/add-plays/", {
        title: 'Add Plays - SaturdayBall.com',
        templateUrl: "static/views/add_plays.html",
        controller: 'AddPlaysController',
        resolve: routeResolver,
        activetab: 'schedule'
      })
      .when("/group/:groupId/settings", {
        title: 'Group Settings - SaturdayBall.com',
        templateUrl: 'static/views/settings.html',
        controller: 'GroupSettingsController',
        resolve: routeResolver,
        activetab: 'settings'
      })
      .when("/group/create/", {
        title: 'Create a Group - SaturdayBall.com',
        templateUrl: 'static/views/creategroup.html',
        controller: 'CreateGroupController',
        resolve: routeResolver
      })
      .when("/register/", {
        title: 'Register - SaturdayBall.com',
        templateUrl: 'static/views/register.html',
        controller: 'RegisterController',
        resolve: routeResolver
      })
      .when("/group/:groupId/leaderboard/", {
        title: 'Leaderboards - SaturdayBall.com',
        templateUrl: 'static/views/leaderboard.html',
        controller: 'LeaderboardController',
        resolve: routeResolver,
        activetab: 'leaderboard'
      })
      .when("/group/:groupId/stats/:playerType/", {
        title: 'Leaderboards - SaturdayBall.com',
        templateUrl: 'static/views/leaderboard.html',
        controller: 'LeaderboardController',
        resolve: routeResolver,
        activetab: 'stats'
      })
      .when("/group/:groupId/players/create", {
        title: 'Player Create - SaturdayBall.com',
        templateUrl: 'static/views/playerform.html',
        controller: 'PlayerFormController',
        resolve: routeResolver,
        activetab: 'players'
      })
      .when("/group/:groupId/players/:playerId/edit", {
        title: 'Player Edit - SaturdayBall.com',
        templateUrl: 'static/views/playerform.html',
        controller: 'PlayerFormController',
        resolve: routeResolver,
        activetab: 'players'
      })
      // .when("/group/:groupId/players/:playerId", {
      //   templateUrl: 'static/views/player.html',
      //   controller: 'PlayerController',
      //   resolve: routeResolver,
      //   activetab: 'players'
      // })
      .when("/groups/:groupId/accept-invite/:inviteCode/", {
        resolve: routeResolver,
      })
      .when("/invite-error/", {
        templateUrl: "static/views/invite-error.html",
        resolve: routeResolver,
      })
      .when("/invite-inactive/", {
        templateUrl: "static/views/invite-inactive.html",
        resolve: routeResolver,
      })
      .otherwise({
        resolve: {
          factory: checkRouting
        }
      });

    $locationProvider.html5Mode(true);
}]);

angular.module('saturdayBall').config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
}]);

var checkRouting= function ($q, $rootScope, $location) {
  var path = $location.path();
  console.log(path);
  window.location = $location.host()+ ':8000' + path;
}

angular.module('saturdayBall').run(['$location', '$rootScope', function($location, $rootScope) {
  $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
    $rootScope.title = current.$$route.title;
  });
}]);
