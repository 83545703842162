'use strict';

angular.module('saturdayBall').controller('PlayerFormController', PlayerFormController);

PlayerFormController.$inject = ['$scope', '$routeParams', 'GroupService', 'PlayerService', 'TeamService',
  '$anchorScroll', '$window'];

function PlayerFormController($scope, $routeParams, GroupService, PlayerService, TeamService,
  $anchorScroll, $window) {

    $scope.formErrors = {};
    $scope.group = {};
    $scope.groupId = $routeParams.groupId;
    $scope.loading = true;
    $scope.playerImage = null;
    $scope.player = {
      group_id: $routeParams.groupId,
      current_team: null,
      is_active: true
    };
    $scope.redirectBackToForm = false;
    $scope.savePlayer = savePlayer;
    $scope.submitDisabled = false;
    $scope.teamOptions = [];

    ///////////////////////

    init();

    function init() {
      if ($routeParams.playerId) {
          PlayerService.getPlayer($routeParams.groupId, $routeParams.playerId).then(function(result){
            $scope.player = result;
            $scope.group = result.group;
            $scope.player.group_id = $routeParams.groupId;
            $scope.playerImage = $scope.player.image_src;
            initializeTeamOptions();
          }, function(result){
            console.log("Error: ", result);
            $scope.loading = false;
          })
      }
      else {
        GroupService.getGroup($routeParams['groupId']).then(function (response){
          $scope.group = response;
          initializeTeamOptions();
        });
        $scope.loading = false;
      }
    }

    function initializeTeamOptions() {
      if ($scope.group.is_league) {
        let params = {is_active: true};
        TeamService.getTeams($routeParams.groupId, params).then(function(result){
          let teams = result;
          $scope.teamOptions = teams.map(function (team) {
            return {id: team.id, name: team.name};
          })
          $scope.loading = false;
        }, function(result){
          console.log("Error: ", result);
          $scope.loading = false;
        });
      } else {
        $scope.loading = false;
      }
    }

    function savePlayer() {
      $scope.submitDisabled = true;
      // $scope.player.group = $routeParams['groupId'];
      if (!_.has($scope.player, 'id')) {
        PlayerService.createPlayer($routeParams.groupId, $scope.player).then(function(result){
          $scope.message = "Player Created";
          handleRedirect(result);
          $scope.player = result;
        }, function(result){
          $scope.formErrors = result.data;
          $scope.submitDisabled = false;
        });
      }
      else {
        if ($scope.player.image_src === null) {
          delete $scope.player.image_src;
        }
        PlayerService.updatePlayer($routeParams.groupId, $scope.player).then(function(result){
          $scope.message = "Player Updated";
          handleRedirect(result);
          $scope.player = result;
        }, function(result){
        });
      }
    }

    function handleRedirect(result) {
      if ($scope.redirectBackToForm) {
        $window.location.href = "/group/"+$routeParams.groupId+"/players/create";
      } else {
        $window.location.href = "/group/"+$routeParams.groupId+"/players/"+result.id;
      }
    }

    var handleFileSelect=function(evt) {
      var file=evt.currentTarget.files[0];
      var reader = new FileReader();
      reader.onload = function (evt) {
        $scope.$apply(function($scope){
          $scope.playerImage=evt.target.result;
        });
      };
      reader.readAsDataURL(file);
    };

    angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);

    $scope.$watchGroup(['player.first_name', 'player.last_name'], function(newVals, oldVals) {
      if(newVals != oldVals) {
        if ((!$scope.player.first_name || !$scope.player.last_name)) {
          $scope.playersWithSameName = [];
          return;
        }
        let params = {
          first_name: $scope.player.first_name,
          last_name: $scope.player.last_name
        }
        PlayerService.getPlayers($routeParams.groupId, params).then(function(result){
          if (result.length > 0){
            // filter out current player
            $scope.playersWithSameName = result.filter(function(player) {
              return player.id != $routeParams.playerId;
            });
            console.log("Players with same name: ", result);
          } else {
            $scope.playersWithSameName = [];
          }
        }, function(result){
          console.warning("Error checking player name: ", result);
        });
      }
    });

  }
