'use strict';

angular.module('saturdayBall')

.controller('GamesController', GamesController);

GamesController.$inject = ['$scope', '$routeParams', 'GameService', 'Session', 'RoleHelper', '$anchorScroll']

function GamesController($scope, $routeParams, GameService, Session, RoleHelper, $anchorScroll) {

  $scope.filters = {
    publishStatus: "published"
  }
  $scope.filteredDailyGames = [];
  $scope.publish_filter_options = [
    {code: "published", name: "Published Games"},
    {code: "unpublished", name: "Unpublished Games"}
  ];
  $scope.games = [];
  $scope.groupId = $routeParams.groupId;
  $scope.loadingPage = true;
  $scope.pageChanged = pageChanged;
  $scope.publishStatusChanged = publishStatusChanged;
  $scope.user = Session.currentUser();

  $scope.pagination = {
    published: true,
    currentPage: 1,
    numPerPage: 12,
    maxSize: 5,
    totalItems: 0
  }

  ///////////////////

  init();

  function init() {
    pageChanged()
  }

  function publishStatusChanged() {
    if ($scope.filters.publishStatus === "unpublished") {
      $scope.pagination.published = false;
      $scope.pagination.currentPage = 1;
    }
    else {
      $scope.pagination.published = true;
      $scope.pagination.currentPage = 1;
    }

    getGamesPage();
  }

  function getGamesPage(published) {
    $scope.loadingPage = true;
    GameService.getGames($routeParams.groupId, $scope.pagination).then(function(results){
      $scope.pagination.currentPage = results.currentPage;
      $scope.pagination.totalItems = results.totalItems;
      if (results.totalItems === 0 && $scope.pagination.published) {
        // No published games, so show unpublished games
        $scope.pagination.published = false;
        GameService.getGames($routeParams.groupId, $scope.pagination).then(function(results){
          $scope.pagination.currentPage = results.currentPage;
          $scope.pagination.totalItems = results.totalItems;
          for (var i=0; i<results.items.length; i++) {
            results.items[i].games = chunk(results.items[i].games,4);
          }
          $scope.filteredDailyGames = results.items;
          $scope.loadingPage = false;
          $scope.filters.publishStatus = "unpublished";
        });
      } else {
        for (var i=0; i<results.items.length; i++) {
          results.items[i].games = chunk(results.items[i].games,4);
        }
        $scope.filteredDailyGames = results.items;
        $scope.loadingPage = false;
      }

    }, function(err){
      console.log(err)
    });
  }

  function pageChanged() {
    if ($scope.pagination.currentPage) {
      $anchorScroll("games-title");
      getGamesPage();
    }
  };

  function chunk(array, chunk_size){
    if(array.length == 0) return [];
    else return [array.splice(0, chunk_size)].concat(chunk(array, chunk_size))
  }

}
